import { useMutation } from "react-query";
import { apiClient } from "lib/api-client";
import { queryClient } from "lib/react-query-client";

const updateConradConfig = data =>
  apiClient.put("api/directory/marketplaces/conrad/update-config", data);

export const useUpdateConradConfig = ({ onSuccess }) => {
  const { mutate: updateConfig, isLoading: isUpdating } = useMutation(
    data => updateConradConfig(data),
    {
      onSuccess: data => {
        onSuccess?.(data);
        queryClient.invalidateQueries("conrad-connection");
      },
    },
  );

  return { updateConfig, isUpdating };
};
