import { useMutation } from "react-query";
import { apiClient } from "lib/api-client";
import { queryClient } from "lib/react-query-client";

const updateEbayConfig = data =>
  apiClient.put("api/directory/marketplaces/ebay/update-config", data);

export const useUpdateEbayConfig = ({ onSuccess }) => {
  const { mutate: updateConfig, isLoading: isUpdating } = useMutation(
    data => updateEbayConfig(data),
    {
      onSuccess: data => {
        onSuccess?.(data);
        queryClient.invalidateQueries("ebay-connection");
      },
    },
  );

  return { updateConfig, isUpdating };
};
