import {
  Form,
  Input,
  Modal,
  FloatButton,
  Button,
  message,
  Tooltip,
} from "antd";
import { PlusOutlined, CopyOutlined } from "@ant-design/icons";
import { useState } from "react";
import { useForm } from "antd/es/form/Form";
import {
  useCreateMarketplaceFormula,
  FORMULAS_OPTIONS,
} from "features/profitability";

const { TextArea } = Input;

const example = "{sale_price}/1.19-{sale_price}*0.07";

export const AddMarketplaceFormula = ({ className = "" }) => {
  const [form] = useForm();

  const [isOpen, setIsOpen] = useState(false);
  const onClose = () => {
    setIsOpen(false);
    form.resetFields();
  };

  const { createFormula, isCreating } = useCreateMarketplaceFormula({
    onSuccess: onClose,
  });

  const onCopy = async () => {
    try {
      await navigator.clipboard.writeText(example);
      message.success("Copied!");
    } catch (e) {
      message.error("Failure! You probably need to set permissions.");
    }
  };

  const title = (
    <div className="flex">
      Create Formula
      <Tooltip title={example}>
        <Button className="mx-auto" size="small" type="link" onClick={onCopy}>
          <CopyOutlined />
          Copy example
        </Button>
      </Tooltip>
    </div>
  );

  return (
    <>
      <FloatButton
        type="primary"
        className={className}
        onClick={() => setIsOpen(true)}
        icon={<PlusOutlined />}
      />

      <Modal
        title={title}
        open={isOpen}
        onCancel={() => !isCreating && onClose()}
        confirmLoading={isCreating}
        onOk={form.submit}
        width={640}
      >
        <Form
          form={form}
          onFinish={createFormula}
          disabled={isCreating}
          className={"mt-6"}
        >
          {FORMULAS_OPTIONS.map(({ name, key, shortName }) => (
            <Form.Item
              key={key}
              required
              name={key}
              label={shortName || name}
              rules={[
                {
                  required: true,
                  message: "Required!",
                },
              ]}
              labelCol={{ span: 6 }}
            >
              <TextArea
                className="font-mono"
                placeholder="{sale_price}"
                autoSize
              />
            </Form.Item>
          ))}
        </Form>
      </Modal>
    </>
  );
};
