import { Modal, Table } from "antd";
import { useApplyingFormulas } from "../../api/show-available-formulas";

export const AvailableFormulas = ({ onCancel, skuData }) => {
  const { availableFormulas, isFetching, isLoading } = useApplyingFormulas({
    ...skuData,
  });

  return (
    <Modal
      footer={null}
      onCancel={onCancel}
      open={true}
      title={<h4 className={"text-center mt-0 mb-6"}>Available Formulas</h4>}
      width={800}
    >
      <Table
        loading={isLoading || isFetching}
        columns={columns}
        dataSource={availableFormulas?.data}
        pagination={false}
      />
    </Modal>
  );
};

const columns = [
  { title: "Marketplace", render: item => Object.keys(item)[0] },
  {
    title: "Formula",
    align: "center",
    render: item => {
      const formulas = Object.values(item)[0];
      return (
        <div className={"flex flex-col gap-2"}>
          <div className={"flex items-center gap-2"}>
            <span className={"text-gray-600 text-xs font-semibold"}>
              {"price > 400:"}
            </span>
            <span className={"font-mono text-green-800"}>
              {formulas.expression_greater}
            </span>
          </div>

          <div className={"flex gap-2 items-center"}>
            <span className={"text-gray-600 text-xs font-semibold"}>
              {"price < 400:"}
            </span>
            <span className={"font-mono text-green-800"}>
              {formulas.expression_less}
            </span>
          </div>
        </div>
      );
    },
  },
];
