import { useMutation } from "react-query";
import { apiClient } from "lib/api-client";
import { queryClient } from "lib/react-query-client";

const updateAmazonConfig = data =>
  apiClient.put("api/directory/marketplaces/amazon/update-config", data);

export const useUpdateAmazonConfig = ({ onSuccess }) => {
  const { mutate: updateConfig, isLoading: isUpdating } = useMutation(
    data => updateAmazonConfig(data),
    {
      onSuccess: data => {
        onSuccess?.(data);
        queryClient.invalidateQueries("amazon-connection");
      },
    },
  );

  return { updateConfig, isUpdating };
};
