import { Card } from "antd";
import { useNavigate } from "react-router-dom";
import { MarketplaceIcon } from "features/marketplaces";
import { useAuthStore } from "features/auth";

export const MarketplaceDirectory = () => {
  const { isRight } = useAuthStore();
  const rightRole = isRight(["admin"]);
  const navigate = useNavigate();

  return (
    <>
      <h2 className="text-center">Marketplaces</h2>

      <div className="flex place-content-center max-w-5xl w-full mx-auto px-4 mt-10 mb-3 flex-wrap gap-4">
        <Card
          hoverable
          onClick={() => rightRole && navigate(`/directory/marketplaces/ebay`)}
        >
          <MarketplaceIcon name="ebay" width="180" height="120" />
        </Card>

        <Card
          hoverable
          onClick={() =>
            rightRole && navigate(`/directory/marketplaces/backmarket`)
          }
        >
          <MarketplaceIcon name="BACKMARKET" width="180" height="120" />
        </Card>

        <Card
          hoverable
          onClick={() =>
            rightRole && navigate(`/directory/marketplaces/refurbed`)
          }
        >
          <MarketplaceIcon name="refurbed" width="180" height="120" />
        </Card>

        <Card
          hoverable
          onClick={() =>
            rightRole && navigate(`/directory/marketplaces/shopify`)
          }
          bodyStyle={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <MarketplaceIcon name="shopify" width="180" height="120" />
        </Card>

        <Card
          hoverable
          onClick={() =>
            rightRole && navigate(`/directory/marketplaces/amazon`)
          }
          bodyStyle={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <MarketplaceIcon name="amazon" width="180" height="120" />
        </Card>

        <Card
          hoverable
          onClick={() =>
            rightRole && navigate(`/directory/marketplaces/mediamarkt`)
          }
          bodyStyle={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <MarketplaceIcon name="mediamarkt" width="180" height="120" />
        </Card>

        <Card
          hoverable
          onClick={() =>
            rightRole && navigate(`/directory/marketplaces/conrad`)
          }
          bodyStyle={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <MarketplaceIcon name="conrad" width="180" height="120" />
        </Card>
      </div>
    </>
  );
};
