import { useMutation } from "react-query";
import { apiClient } from "lib/api-client";
import { queryClient } from "lib/react-query-client";

const saveRefurbedConfig = data =>
  apiClient.post("api/directory/marketplaces/refurbed/save-config", data);

export const useSaveRefurbedConfig = ({ onSuccess }) => {
  const { mutate: saveConfig, isLoading: isSaving } = useMutation(
    data => saveRefurbedConfig(data),
    {
      onSuccess: data => {
        onSuccess?.(data);
        queryClient.invalidateQueries("refurbed-connection");
      },
    },
  );

  return { saveConfig, isSaving };
};
