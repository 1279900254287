import { Link } from "react-router-dom";

export const connectedProductColumns = [
  {
    title: "Invoice",
    dataIndex: "invoice",
    render: ({ invoice_number, invoice_id }) => (
      <Link className="whitespace-nowrap" to={`/invoices/manage/${invoice_id}`}>
        {invoice_number}
      </Link>
    ),
    align: "center",
  },
  {
    title: "Device id",
    dataIndex: "product_id",
    render: product_id => (
      <Link className="whitespace-nowrap" to={`/devices/${product_id}`}>
        {product_id}
      </Link>
    ),
    align: "center",
  },
  {
    title: "Single Price",
    dataIndex: "invoice_product",
    align: "center",
    render: invoice_product =>
      invoice_product?.single_price ? (
        <strong className={"font-semibold text-gray-700"}>
          €{invoice_product?.single_price}
        </strong>
      ) : (
        ""
      ),
  },
];

export const disconnectedProductColumns = connectedProductColumns.toSpliced(
  2,
  1,
);
