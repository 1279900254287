import React from "react";
import { Routes, Route } from "react-router-dom";
import {
  Root,
  Login,
  Dashboard,
  SearchDevice,
  Invoices,
  Employees,
  Employee,
  DevicePage,
  Invoice,
  InvoiceRetour,
  ErrorPage,
  ColorDirectory,
  DepartmentDirectory,
  CreateDevice,
  DeviceType,
  DevicesDirectory,
  Grade,
  GradeDirectory,
  MarketplaceDirectory,
  EbayMarketplace,
  SKUDirectory,
  SupplierDirectory,
  AddSupplier,
  Supplier,
  CreateColor,
  ColorType,
  CreateDepartment,
  EditDepartment,
  CreateSKU,
  EditSKU,
  Sales,
  OnSaleDevices,
  OnSaleSku,
  CompanyStatistics,
  Logs,
  SendingReadyList,
  CommissionListDevices,
  RefurbedConfig,
  SendingDevices,
  SendingAccessories,
  SendingReadyAccessoryList,
  CommissionListAccessory,
  BackMarketConfig,
  OnSaleSKUs,
  InvoiceExport,
  ShopifyConfig,
  Promotions,
  Promotion,
  CreatePromotion,
  AmazonConfig,
  Formulas,
  B2BImport,
  MarketplaceFormulas,
  ConnectedDevices,
  ConnectedSKUs,
  DisconnectedDevices,
  DisconnectedSKUs,
  ProductsWithoutSale,
  ProductsWithoutSaleBySku,
  ProductsWithOtherStatuses,
  SKUMonitoring,
  Accessories,
  AccessoryCategories,
  SalesIssues,
  CompareQuantity,
  MediaMarktConfig,
  ConradConfig,
  AveragePriceExport,
} from "routes";

import { WithAuthorization } from "components";
import "./index.css";

const visorExcluded = [
  "admin",
  "registrar",
  "grader",
  "flasher",
  "packer",
  "sales_manager",
  "sender",
  "head_of_flasher",
];

function AppRouter() {
  return (
    <Routes>
      <Route path="*" element={<ErrorPage />} />
      <Route path="/error" element={<ErrorPage />} />
      <Route path="/login" element={<Login />} />

      <Route path="/" element={<Root />}>
        <Route
          path="/dashboard"
          element={
            <WithAuthorization
              permissions={[
                "admin",
                "sales_manager",
                "grader",
                "flasher",
                "packer",
                "head_of_flasher",
              ]}
            >
              <Dashboard />
            </WithAuthorization>
          }
        />
        <Route path="/devices" element={<SearchDevice />} />
        <Route path="/devices/:deviceId" element={<DevicePage />} />
        <Route
          path="/invoices/manage"
          element={
            <WithAuthorization
              permissions={[
                "admin",
                "sales_manager",
                "prioritizer",
                "registrar",
              ]}
            >
              <Invoices />
            </WithAuthorization>
          }
        />

        <Route
          path="/invoices/export"
          element={
            <WithAuthorization
              permissions={[
                "admin",
                "sales_manager",
                "prioritizer",
                "registrar",
              ]}
            >
              <InvoiceExport />
            </WithAuthorization>
          }
        />
        <Route
          path="/invoices/manage/:invoiceId"
          element={
            <WithAuthorization
              permissions={[
                "admin",
                "sales_manager",
                "prioritizer",
                "registrar",
              ]}
            >
              <Invoice />
            </WithAuthorization>
          }
        />

        <Route
          path="/invoices/manage/retour/:retourId"
          element={
            <WithAuthorization
              permissions={[
                "admin",
                "sales_manager",
                "prioritizer",
                "registrar",
              ]}
            >
              <InvoiceRetour />
            </WithAuthorization>
          }
        />

        <Route
          path="/sales"
          element={
            <WithAuthorization permissions={["admin", "sales_manager"]}>
              <Sales />
            </WithAuthorization>
          }
        />

        {/* PROMOTIONS */}
        <Route
          path="/promotions"
          element={
            <WithAuthorization permissions={["admin", "sales_manager"]}>
              <Promotions />
            </WithAuthorization>
          }
        />
        <Route
          path="/promotions/:promotionId"
          element={
            <WithAuthorization permissions={["admin", "sales_manager"]}>
              <Promotion />
            </WithAuthorization>
          }
        />
        <Route
          path="/promotions/create"
          element={
            <WithAuthorization permissions={["admin", "sales_manager"]}>
              <CreatePromotion />
            </WithAuthorization>
          }
        />

        <Route
          path="/sales-control/devices"
          element={
            <WithAuthorization permissions={["admin", "sales_manager"]}>
              <OnSaleDevices />
            </WithAuthorization>
          }
        />
        <Route
          path="/sales-control/sku"
          element={
            <WithAuthorization permissions={["admin", "sales_manager"]}>
              <OnSaleSKUs />
            </WithAuthorization>
          }
        />

        <Route
          path="/sales-control/formulas"
          element={
            <WithAuthorization permissions={["admin", "sales_manager"]}>
              <Formulas />
            </WithAuthorization>
          }
        />

        <Route
          path="/sales-control/sku-monitoring"
          element={
            <WithAuthorization permissions={["admin", "sales_manager"]}>
              <SKUMonitoring />
            </WithAuthorization>
          }
        />
        <Route
          path="/sales-control/b2b-import"
          element={
            <WithAuthorization permissions={["admin", "sales_manager"]}>
              <B2BImport />
            </WithAuthorization>
          }
        />
        <Route
          path="/sales-control/issues"
          element={
            <WithAuthorization permissions={["admin", "sales_manager"]}>
              <SalesIssues />
            </WithAuthorization>
          }
        />
        <Route
          path="/sales/sku/:skuId/:gradeId"
          element={
            <WithAuthorization permissions={["admin", "sales_manager"]}>
              <OnSaleSku />
            </WithAuthorization>
          }
        />
        <Route
          path="/sending/devices"
          element={
            <WithAuthorization permissions={["admin", "sender"]}>
              <SendingDevices />
            </WithAuthorization>
          }
        />
        <Route
          path="/sending/accessories"
          element={
            <WithAuthorization permissions={["admin", "sender"]}>
              <SendingAccessories />
            </WithAuthorization>
          }
        />
        <Route
          path="/sending/devices/ready"
          element={
            <WithAuthorization permissions={["admin", "sender"]}>
              <SendingReadyList />
            </WithAuthorization>
          }
        />
        <Route
          path="/sending/accessories/ready"
          element={
            <WithAuthorization permissions={["admin", "sender"]}>
              <SendingReadyAccessoryList />
            </WithAuthorization>
          }
        />
        <Route
          path="/sending/devices/commission-list/:commissionListId"
          element={
            <WithAuthorization permissions={["admin", "sender"]}>
              <CommissionListDevices />
            </WithAuthorization>
          }
        />
        <Route
          path="/sending/accessories/commission-list/:accessoryCommissionListId"
          element={
            <WithAuthorization permissions={["admin", "sender"]}>
              <CommissionListAccessory />
            </WithAuthorization>
          }
        />

        {/* FAKTURA*/}
        <Route
          path="/faktura/compare"
          element={
            <WithAuthorization>
              <CompareQuantity />
            </WithAuthorization>
          }
        />
        <Route
          path="/faktura/average-price-export"
          element={
            <WithAuthorization>
              <AveragePriceExport />
            </WithAuthorization>
          }
        />

        {/* DIRECTORY */}
        <Route
          path="/directory/accessory"
          element={
            <WithAuthorization permissions={visorExcluded}>
              <Accessories />
            </WithAuthorization>
          }
        />
        <Route
          path="/directory/accessory/categories"
          element={
            <WithAuthorization permissions={visorExcluded}>
              <AccessoryCategories />
            </WithAuthorization>
          }
        />
        <Route
          path="/directory/devices"
          element={
            <WithAuthorization permissions={visorExcluded}>
              <DevicesDirectory />
            </WithAuthorization>
          }
        />
        <Route
          path="/directory/devices/:deviceId"
          element={
            <WithAuthorization permissions={visorExcluded}>
              <DeviceType />
            </WithAuthorization>
          }
        />
        <Route
          path="/directory/devices/create"
          element={
            <WithAuthorization
              permissions={["admin", "registrar", "head_of_flasher"]}
            >
              <CreateDevice />
            </WithAuthorization>
          }
        />
        <Route
          path="/directory/colors"
          element={
            <WithAuthorization permissions={visorExcluded}>
              <ColorDirectory />
            </WithAuthorization>
          }
        />
        <Route
          path="/directory/colors/create"
          element={
            <WithAuthorization
              permissions={["admin", "registrar", "head_of_flasher"]}
            >
              <CreateColor />
            </WithAuthorization>
          }
        />
        <Route
          path="/directory/colors/:colorId"
          element={
            <WithAuthorization permissions={visorExcluded}>
              <ColorType />
            </WithAuthorization>
          }
        />
        <Route
          path="/directory/grades"
          element={
            <WithAuthorization permissions={visorExcluded}>
              <GradeDirectory />
            </WithAuthorization>
          }
        />
        <Route
          path="/directory/grades/:gradeId"
          element={
            <WithAuthorization permissions={visorExcluded}>
              <Grade />
            </WithAuthorization>
          }
        />
        <Route
          path="/directory/sku"
          element={
            <WithAuthorization permissions={visorExcluded}>
              <SKUDirectory />
            </WithAuthorization>
          }
        />
        <Route
          path="/directory/sku/create"
          element={
            <WithAuthorization
              permissions={["admin", "registrar", "head_of_flasher"]}
            >
              <CreateSKU />
            </WithAuthorization>
          }
        />
        <Route
          path="/directory/sku/:skuId"
          element={
            <WithAuthorization
              permissions={["admin", "registrar", "head_of_flasher"]}
            >
              <EditSKU />
            </WithAuthorization>
          }
        />
        <Route
          path="/directory/suppliers"
          element={
            <WithAuthorization permissions={visorExcluded}>
              <SupplierDirectory />
            </WithAuthorization>
          }
        />
        <Route
          path="/directory/suppliers/adding"
          element={
            <WithAuthorization
              permissions={["admin", "registrar", "head_of_flasher"]}
            >
              <AddSupplier />
            </WithAuthorization>
          }
        />
        <Route
          path="/directory/suppliers/:supplierId"
          element={
            <WithAuthorization permissions={visorExcluded}>
              <Supplier />
            </WithAuthorization>
          }
        />
        <Route
          path="/directory/marketplaces"
          element={
            <WithAuthorization>
              <MarketplaceDirectory />
            </WithAuthorization>
          }
        />
        <Route
          path="/directory/marketplaces/ebay"
          element={
            <WithAuthorization>
              <EbayMarketplace />
            </WithAuthorization>
          }
        />
        <Route
          path="/directory/marketplaces/refurbed"
          element={
            <WithAuthorization>
              <RefurbedConfig />
            </WithAuthorization>
          }
        />
        <Route
          path="/directory/marketplaces/backmarket"
          element={
            <WithAuthorization>
              <BackMarketConfig />
            </WithAuthorization>
          }
        />
        <Route
          path="/directory/marketplaces/shopify"
          element={
            <WithAuthorization>
              <ShopifyConfig />
            </WithAuthorization>
          }
        />
        <Route
          path="/directory/marketplaces/amazon"
          element={
            <WithAuthorization>
              <AmazonConfig />
            </WithAuthorization>
          }
        />
        <Route
          path="/directory/marketplaces/mediamarkt"
          element={
            <WithAuthorization>
              <MediaMarktConfig />
            </WithAuthorization>
          }
        />
        <Route
          path="/directory/marketplaces/conrad"
          element={
            <WithAuthorization>
              <ConradConfig />
            </WithAuthorization>
          }
        />
        <Route
          path="/directory/departments"
          element={
            <WithAuthorization permissions={visorExcluded}>
              <DepartmentDirectory />
            </WithAuthorization>
          }
        />
        <Route
          path="/directory/departments/create"
          element={
            <WithAuthorization>
              <CreateDepartment />
            </WithAuthorization>
          }
        />
        <Route
          path="/directory/departments/:departmentId"
          element={
            <WithAuthorization permissions={visorExcluded}>
              <EditDepartment />
            </WithAuthorization>
          }
        />

        <Route
          path="/employees"
          element={
            <WithAuthorization>
              <Employees />
            </WithAuthorization>
          }
        />

        {/* PROFIT. FOR SUPERUSERS ONLY*/}
        <Route path="/profit/formulas" element={<MarketplaceFormulas />} />
        <Route
          path="/profit/connected/devices"
          element={<ConnectedDevices />}
        />
        <Route
          path="/profit/disconnected/devices"
          element={<DisconnectedDevices />}
        />
        <Route
          path="/profit/other-status/devices"
          element={<ProductsWithOtherStatuses />}
        />
        <Route
          path="/profit/without-sale/devices"
          element={<ProductsWithoutSale />}
        />
        <Route path="/profit/connected/skus" element={<ConnectedSKUs />} />
        <Route
          path="/profit/disconnected/skus"
          element={<DisconnectedSKUs />}
        />
        <Route
          path="/profit/without-sale/skus"
          element={<ProductsWithoutSaleBySku />}
        />

        {/* STATISTICS */}
        <Route
          path="/statistics"
          element={
            <WithAuthorization permissions={visorExcluded}>
              <CompanyStatistics />
            </WithAuthorization>
          }
        />
        <Route
          path="/logs"
          element={
            <WithAuthorization permissions={["head_of_flasher", "admin"]}>
              <Logs />
            </WithAuthorization>
          }
        />
        <Route
          path="/employees/:employeeId"
          element={
            <WithAuthorization>
              <Employee />
            </WithAuthorization>
          }
        />
      </Route>
    </Routes>
  );
}

export default AppRouter;
