import { useMutation } from "react-query";
import { apiClient } from "lib/api-client";
import { queryClient } from "lib/react-query-client";

const updateRefurbedConfig = data => {
  return apiClient.put("api/directory/marketplaces/refurbed/update-config", {
    ...data,
  });
};

export const useUpdateRefurbedConfig = ({ onSuccess }) => {
  const { mutate: updateConfig, isLoading: isUpdating } = useMutation(
    data => updateRefurbedConfig(data),
    {
      onSuccess: data => {
        onSuccess?.(data);
        queryClient.invalidateQueries("refurbed-connection");
      },
    },
  );

  return { updateConfig, isUpdating };
};
