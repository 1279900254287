import { Loader } from "components";
import dayjs from "dayjs";
import { useAuthStore } from "features/auth";
import { useMarketplaceFormulas } from "features/profitability";
import { Navigate } from "react-router-dom";
import { queryClient } from "lib/react-query-client";

export const ProfitabilityPage = ({
  children,
  store,
  title,
  preloadFormula = false,
}) => {
  const { user } = useAuthStore();
  const superUsers = queryClient.getQueryData(["superusers"])?.data;

  useMarketplaceFormulas({
    enabled: preloadFormula,
    onSuccess: formulas => {
      if (!!formulas.length)
        store?.setFilter({
          // to allow start products fetching
          market_formula: formulas[0]?.id,
        });

      if (!store?.start_date) {
        const d = new Date();
        d.setDate(d.getDate() - 3);
        store?.setStartDate(dayjs(d).format("YYYY-MM-DD"));
      }
    },
  });

  const isChecking = !user || !superUsers;
  const isNoSuperUser =
    user && superUsers && !superUsers?.includes(user.data.id);

  if (isNoSuperUser) return <Navigate to="/" replace />;

  return (
    <div className="page-container flex-col relative">
      {isChecking ? (
        <Loader />
      ) : (
        <>
          <h2 className="text-center mb-6 sm:mb-10">{title}</h2>
          {children}
        </>
      )}
    </div>
  );
};
