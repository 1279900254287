import { useQuery } from "react-query";
import { apiClient } from "lib/api-client";

const getAmazonInfo = () =>
  apiClient.get("api/directory/marketplaces/amazon/show");

export const useAmazonInfo = ({ onSuccess, onError } = {}) => {
  const {
    data: info,
    isLoading: isLoadingInfo,
    isFetching: isFetchingInfo,
    error: infoError,
  } = useQuery(["amazon-connection"], getAmazonInfo, {
    onSuccess: data => {
      onSuccess?.(data);
    },
    onError: err => {
      onError?.(err);
    },
  });
  return { info, isLoadingInfo, isFetchingInfo, infoError };
};
