import { useNavigate, useLocation } from "react-router-dom";
import _ from "lodash";
import { Layout, Menu } from "antd";
import { useAuthStore } from "features/auth";
import { useSuperUsers } from "features/employees";
import { menuItems } from "./config";

export const NavigationMenu = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { isRight, user } = useAuthStore();
  const { superUsers } = useSuperUsers();

  const userHasPermission = permissionsArr => {
    if (!permissionsArr) return true;
    const superCase = permissionsArr.includes("super") && !!superUsers;
    return isRight(permissionsArr, superCase ? superUsers : undefined);
  };

  const getPermittedMenu = menu => {
    menu.forEach((menuItem, i) => {
      if (!userHasPermission(menuItem.permissions)) return menu.splice(i, 1);
      if ("children" in menuItem)
        menuItem.children = getPermittedMenu(menuItem.children);
    });
    return menu;
  };

  const getActiveItems = menu => {
    const result = [];
    const collect = menu => {
      menu.forEach(item => {
        if (item.key === pathname) result.push(item.key);
        if ("children" in item) collect(item.children);
      });
    };
    collect(menu);
    return result;
  };

  const permittedMenu = getPermittedMenu([...menuItems]);
  const activeItems = getActiveItems(permittedMenu);

  if (_.isEqual(user.data.roles, ["visor"])) return null;

  return (
    <Layout.Sider
      className="h-full z-10 [&_div]:overflow-y-auto !absolute md:!static"
      theme="light"
      breakpoint="md"
      collapsedWidth="0"
    >
      <Menu
        theme="light"
        selectedKeys={activeItems}
        onClick={val => {
          navigate(val.key);
          toggleMobileMenu();
        }}
        items={getPermittedMenu(permittedMenu)}
        triggerSubMenuAction="click"
        mode="inline"
      />
    </Layout.Sider>
  );
};

const toggleMobileMenu = () => {
  const btn = document.querySelector(
    ".ant-layout-sider-zero-width-trigger.ant-layout-sider-zero-width-trigger-left",
  );

  if (btn) {
    const clickEvent = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: false,
    });

    btn.dispatchEvent(clickEvent);
  }
};
