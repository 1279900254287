import { Collapse, List, Tag } from "antd";
import dayjs from "dayjs";
import clsx from "clsx";
import {
  AddMarketplaceFormula,
  useMarketplaceFormulas,
  FORMULAS_OPTIONS,
  useFormulasStore,
  ProfitabilityPage,
} from "features/profitability";
import { Loader, Pagination } from "components";

export const MarketplaceFormulas = () => {
  const store = useFormulasStore();
  const { formulas, isLoading, isFetching } = useMarketplaceFormulas({ store });

  return (
    <ProfitabilityPage title="Marketplace Formulas">
      <div
        className={clsx("relative max-w-5xl w-full mx-auto px-4", {
          hidden: !formulas || isLoading,
        })}
      >
        <AddMarketplaceFormula className="absolute top-0 right-8" />
      </div>

      <div
        className={clsx("overflow-y-auto max-w-5xl w-full mx-auto px-4 mt-5", {
          hidden: !formulas,
        })}
      >
        <Collapse accordion>
          {formulas?.data?.map((f, i) => {
            const isCurrent = store.currentFormula === f.id;
            const header = (
              <div className="flex gap-2">
                <Tag>{f.id}</Tag>
                <time>{dayjs(f.created_at).format("MMM-DD-YYYY H:mm")}</time>
                {isCurrent && (
                  <Tag className="ml-3" color="magenta">
                    Current
                  </Tag>
                )}
              </div>
            );

            return (
              <Collapse.Panel header={header} key={f.id}>
                <List
                  dataSource={FORMULAS_OPTIONS.map(({ key, name }) => ({
                    title: name,
                    value: f[key],
                  }))}
                  renderItem={({ title, value }) => (
                    <List.Item>
                      <List.Item.Meta
                        title={title}
                        description={
                          <code className="text-green-900 pl-1">{value}</code>
                        }
                      />
                    </List.Item>
                  )}
                />
              </Collapse.Panel>
            );
          })}
        </Collapse>
      </div>

      <Pagination
        store={store}
        disabled={isLoading || isFetching}
        className="mt-auto"
      />

      {(isLoading || isFetching) && <Loader />}
    </ProfitabilityPage>
  );
};
