import { useMutation } from "react-query";
import { apiClient } from "lib/api-client";
import { queryClient } from "lib/react-query-client";

const updateShopifyConfig = data =>
  apiClient.put("api/directory/marketplaces/shopify/update-config", data);

export const useUpdateShopifyConfig = ({ onSuccess }) => {
  const { mutate: updateConfig, isLoading: isUpdating } = useMutation(
    data => updateShopifyConfig(data),
    {
      onSuccess: data => {
        onSuccess?.(data);
        queryClient.invalidateQueries("shopify-connection");
      },
    },
  );

  return { updateConfig, isUpdating };
};
