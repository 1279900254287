import { useMutation } from "react-query";
import { apiClient } from "lib/api-client";
import { queryClient } from "lib/react-query-client";

const removeConfig = () =>
  apiClient.delete("api/directory/marketplaces/amazon/delete");

export const useRemoveAmazonConfig = ({ onSuccess }) => {
  const { mutate: remove, isLoading: isRemoving } = useMutation(removeConfig, {
    onSuccess: data => {
      onSuccess?.(data);
      queryClient.invalidateQueries("amazon-connection");
    },
  });

  return { remove, isRemoving };
};
