import { useEffect } from "react";
import { Modal, Pagination, Table } from "antd";
import { connectedProductColumns } from "./productsTableConfig";
import {
  useSkuLatestConnectedProductsStore,
  useSkuLatestConnectedProducts,
} from "features/sales";

export const LatestConnectedProducts = ({
  onClose,
  sku_id,
  grade_id,
  original_box,
}) => {
  const store = useSkuLatestConnectedProductsStore();
  const { connectedProducts, isLoadingConnected, isFetchingConnected } =
    useSkuLatestConnectedProducts({
      sku_id,
      grade_id,
      original_box,
    });

  useEffect(() => {
    return () => store.setPage(1);
  }, []);

  return (
    <Modal
      open={true}
      onCancel={onClose}
      title={
        <div className={"text-center mb-4"}>Latest Connected Products</div>
      }
      footer={
        <Pagination
          className="text-center  mt-auto"
          onShowSizeChange={(_, pageSize) => store.setPageSize(pageSize)}
          pageSizeOptions={[4, 8, 12, 24, 48]}
          showSizeChanger
          defaultCurrent={1}
          total={store.total}
          current={store.page}
          pageSize={store.pageSize}
          simple
          onChange={store.setPage}
          disabled={isLoadingConnected || isFetchingConnected}
        />
      }
    >
      <Table
        loading={isLoadingConnected || isFetchingConnected}
        columns={connectedProductColumns}
        dataSource={connectedProducts?.data}
        pagination={false}
        rowKey={record => record.product_id}
      />
    </Modal>
  );
};
