import { useEffect } from "react";
import _ from "lodash";
import { requiredRule } from "constants/validation";
import { Button, Form, Input, Alert, Card, App } from "antd";
import {
  useMediaMarktInfo,
  useUpdateMediaMarktConfig,
  useRemoveMediaMarktConfig,
  useSaveMediaMarktConfig,
} from "features/marketplaces";
import { Loader } from "components";

export const MediaMarktConfig = () => {
  const { modal, message } = App.useApp();
  const [form] = Form.useForm();

  const { info, isLoadingInfo, isFetchingInfo } = useMediaMarktInfo();

  const { saveConfig, isSaving } = useSaveMediaMarktConfig();

  const { updateConfig, isUpdating } = useUpdateMediaMarktConfig({
    onSuccess: data => message.success(data.message || "Success"),
  });

  const { remove, isRemoving } = useRemoveMediaMarktConfig({
    onSuccess: data => message.success(data.message || "Success"),
  });

  useEffect(() => {
    if (info) {
      form.setFieldValue("shop_id", info.data.shop_id);
      form.setFieldValue("site_url", info.data.site_url);
      form.setFieldValue("api_key", info.data.auth_token);
    }
    // eslint-disable-next-line
  }, [info]);

  const noConfig = _.isEmpty(info?.data);
  const anyProcessing =
    isSaving || isUpdating || isRemoving || isFetchingInfo || isLoadingInfo;

  return (
    <div className="flex flex-col max-w-lg w-full mx-auto relative">
      <h2 className="text-center">MediaMarkt</h2>

      {info?.data && noConfig && (
        <Alert
          className="mb-5 mt-6"
          type="error"
          message="First you need create config"
        />
      )}

      {info?.data?.is_connect && (
        <Alert
          className="mb-12 mt-8 "
          type="success"
          showIcon
          message="Connected"
        />
      )}

      {info?.data.is_connect === false && (
        <Alert
          className=" mb-5 mt-6"
          type="error"
          message="Need to update config"
        />
      )}

      <Card title="Config Settings">
        <Form
          form={form}
          requiredMark
          onFinish={noConfig ? saveConfig : updateConfig}
          disabled={anyProcessing}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 20 }}
        >
          <Form.Item label="Site URL" name="site_url" rules={[requiredRule]}>
            <Input placeholder="Enter Site URL" />
          </Form.Item>

          <Form.Item label="Api Key" name="api_key" rules={[requiredRule]}>
            <Input placeholder="Enter Api Key" />
          </Form.Item>

          <Form.Item label="Shop ID" name="shop_id" rules={[requiredRule]}>
            <Input placeholder="Enter Shop ID" />
          </Form.Item>

          <div className="flex justify-end gap-2">
            {noConfig ? (
              <Button type="primary" htmlType="submit" loading={isSaving}>
                Create
              </Button>
            ) : (
              <>
                <Button
                  danger
                  loading={isRemoving}
                  onClick={() => {
                    modal.confirm({
                      title: "Config Deleting",
                      content: "Are you sure you want to delete the config?",
                      onOk: () => remove(),
                    });
                  }}
                >
                  Delete
                </Button>

                <Button htmlType="submit" loading={isUpdating}>
                  Update
                </Button>
              </>
            )}
          </div>

          {(isLoadingInfo || isFetchingInfo) && <Loader />}
        </Form>
      </Card>
    </div>
  );
};
