import { useMutation } from "react-query";
import { apiClient } from "lib/api-client";
import { queryClient } from "lib/react-query-client";

const saveShopifyConfig = data =>
  apiClient.post("api/directory/marketplaces/shopify/save-config", data);

export const useSaveShopifyConfig = ({ onSuccess }) => {
  const { mutate: saveConfig, isLoading: isSaving } = useMutation(
    data => saveShopifyConfig(data),
    {
      onSuccess: data => {
        onSuccess?.(data);
        queryClient.invalidateQueries("shopify-connection");
      },
    },
  );

  return { saveConfig, isSaving };
};
