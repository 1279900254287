import { useQuery } from "react-query";
import { apiClient } from "lib/api-client";
import { globalErrorHandler } from "lib/react-query-client";

const getMediaMarktInfo = () =>
  apiClient.get("api/directory/marketplaces/mediamarkt/show");

export const useMediaMarktInfo = ({ onSuccess, onError } = {}) => {
  const {
    data: info,
    isLoading: isLoadingInfo,
    isFetching: isFetchingInfo,
    error: infoError,
  } = useQuery(["mediamarkt-connection"], getMediaMarktInfo, {
    onSuccess: data => {
      onSuccess?.(data);
    },
    onError: err => {
      globalErrorHandler(err);
      onError?.(err);
    },
    retry: false,
  });
  return { info, isLoadingInfo, isFetchingInfo, infoError };
};
