import { useMutation } from "react-query";
import { apiClient } from "lib/api-client";
import { queryClient } from "lib/react-query-client";

const saveBackMarketConfig = data =>
  apiClient.post("api/directory/marketplaces/backmarket/save-config", data);

export const useSaveBackMarketConfig = ({ onSuccess }) => {
  const { mutate: saveConfig, isLoading: isSaving } = useMutation(
    data => saveBackMarketConfig(data),
    {
      onSuccess: data => {
        onSuccess?.(data);
        queryClient.invalidateQueries("backmarket-connection");
      },
    },
  );

  return { saveConfig, isSaving };
};
