import { Button, InputNumber, message, Space, Modal } from "antd";
import { useState } from "react";
import { useApplyFormulas } from "features/sales";
import { useCheckingFormulasProfit } from "features/profitability";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { AvailableFormulas } from "./AvailableFormulas";
import { FORMULAS_OPTIONS } from "features/profitability";

export const ApplyFormulas = ({ skuData }) => {
  const [price, setPrice] = useState(null);
  const [showFormulas, setShowFormulas] = useState(false);

  const { applyFormulas, isApplying } = useApplyFormulas({
    onSuccess: res => {
      message.success(res?.message);
      setPrice(null);
    },
  });

  const onApply = () => {
    applyFormulas({
      ...skuData,
      price,
      original_box: Boolean(skuData.original_box),
    });
  };

  const { checkFormulasProfit, isChecking } = useCheckingFormulasProfit({
    onSuccess: ({ data }) => {
      Modal.confirm({
        title: "Confirm the applied values",
        width: 600,
        mask: false,
        maskClosable: true,
        content: (
          <div className="my-3">
            {Object.entries(data).map(([k, v]) => (
              <div key={k} className="flex gap-6 my-1">
                <span className="w-1/3 text-gray-600 font-semibold">
                  {FORMULAS_OPTIONS.find(({ key, value }) => key === k).name}
                </span>
                <span className="flex-1 font-mono text-green-800">{v}</span>
              </div>
            ))}
          </div>
        ),
        onOk: onApply,
      });
    },
  });

  return (
    <>
      <Space.Compact className={"ml-auto mr-2"}>
        <Button
          onClick={() => setShowFormulas(true)}
          className={"text-gray-400"}
          icon={<QuestionCircleOutlined />}
        />
        <InputNumber
          min={0}
          value={price}
          onChange={setPrice}
          disabled={isApplying}
          placeholder={" Price"}
        />
        <Button
          onClick={() =>
            checkFormulasProfit({
              data: {
                price,
                ...skuData,
                original_box: Boolean(skuData.original_box),
              },
            })
          }
          disabled={!price}
          loading={isChecking || isApplying}
        >
          Apply Formulas
        </Button>
      </Space.Compact>

      {showFormulas && (
        <AvailableFormulas
          onCancel={() => setShowFormulas(false)}
          skuData={skuData}
        />
      )}
    </>
  );
};
